$(function(){
	$('.list-expanding').each(function(){
		var items = $(this).find('.list-expanding-item'),
			headers = $(this).find('.expanding-header');
		headers.click(function(){
			var parent = $(this).parent();
			if(parent.hasClass('active')){
				items.filter('.active').removeClass('active').find('.expanding-main').slideUp();
			} else{
				items.filter('.active').removeClass('active').find('.expanding-main').slideUp();
				parent.addClass('active');
				parent.find('.expanding-main').slideDown();
			}
		});
	});
});