$(function(){
	$('.map-journey').each(function(){
		var scope = $(this),
			pois = $(this).find('.map-journey-poi'),
			map = scope.find('.map'),
			$footer = $('<footer>',{class:'map-journey-footer'});
		
		$footer.hide().appendTo(scope);

		pois.each(function(){
			// Push colour to footer content
			var color = $(this).attr('style');
			$(this).find('main i').attr('style',color);

			// Add footer content to footer
			var cont = $(this).find('main').clone().hide();
			$footer.append(cont);
		});

		var $infos = $footer.find('main');

		var path = scope.find('.cls-1');

		body.on('scrolld',function(){
			// Activate Info Panels
			var n = -1;
			pois.each(function(i){
				if($(this).offset().top < scrolld + 0.5 * winH){
					n = i;
				}
			});
			if(n > -1 && n != $infos.filter(':visible').last().index()){
				$infos.hide().eq(n).fadeIn();
				if($footer.is(':hidden')){
					$footer.slideDown();
				}
			}
			// Draw Path
			var pathLength = path[0].getTotalLength(),
				mapX = map.offset().top - (0.65 * winH),
				mapY = map.height(),
				progress = (scrolld - mapX) / mapY;
			path.css({
				'stroke-dasharray': pathLength,
  				'stroke-dashoffset': pathLength - progress * pathLength
			})
		});

	});
});