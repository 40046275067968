function clearSearchResults(el){
	el.find('.list-results-item').filter(':not(.template)').remove();
}

function getSearchResults(results,el){

	// EMPTY

	clearSearchResults(el);

	var template = el.find('.list-results-item.template').first();

	for (var i = 0; i < results.length; i++) {
     	// Draw Item
     	var item = template.clone().removeClass('template'),
     		cont = results[i];

     	// Populate
     	var img = item.find('.sr-img'),
     		url = item.find('.sr-url'),
     		title = item.find('.sr-title'),
     		cat = item.find('.sr-cat'),
     		date = item.find('.sr-date');
 		
 		img.attr('src',cont.Image);
 		url.attr('href',cont.Url);
 		title.text(cont.Title);
 		cat.text(cont.Category);
 		date.text(cont.Date);

 		if(date.text().length < 2){
 			date.remove();
 		}
 		el.find('.list-results-view-all').before(item);
    }
    el.fadeIn();
}

$(function(){
	$('.search-module-input').each(function(){
		var input = $(this).find('input'),
			res = $(this).find('.search-module-results'),
			src = $(this).attr('data-source');
		input.on('keyup',function(){
			$.getJSON(src, function( response ) {
			   getSearchResults(response.results,res);
			});
		})
		$('body').click(function(){
			res.fadeOut();
		})
	});
});