$(function() {

    var listCards = $(".list-cards");

    listCards.each(function() {
        if ($(this).find("li.list-cards-item").length <= 3) {
            $(this).addClass("centre")
        }
    })

})