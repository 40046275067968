$(function(){
	$('.list-categories').each(function(){
		var scope = $(this),
			items = $(this).find('.list-categories-item');

		var $slider = scope.flickity({
				pageDots:false,
				cellAlign:'center',
				prevNextButtons:false,
				contain:true,
				freeScroll:false,
				wrapAround:false,
				draggable:true,
				imagesLoaded:true
			});
	});
});