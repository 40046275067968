$(function(){
	var t = 800;
	$('.team-showcase').each(function(){
		var members = $(this).find('.team-showcase-item'),
			isVisible = members.filter(':visible');
		$(this).empty();
		for (i = 0; i < isVisible.length; i++){
			var startItem = $(('<li class="team-showcase-item container">'));
			startItem.html(members.eq(i).clone());
			$(this).append(startItem);
		}
		var x = 0,
			y = isVisible.length,
			container = $(this).find('.container');
		var animate = setInterval(function(){
			if(x == isVisible.length){
				x = 0;
			}
			if(y == members.length){
				y = 0;
			}
			var target = members.eq(y),
				cont = container.eq(x);
			cont.fadeOut(t)
			setTimeout(function(){
				cont.empty().html(target.clone()).fadeIn(t);
				x ++;
				y ++;
			},t-5);
		},t*4);
	});	
});