$(function(){
	$('.list-accreditations').each(function(){
		var scope = $(this),
			items = scope.find('.list-accreditations-item'),
			$slider = $(this).flickity({
				pageDots:false,
				cellAlign:'center',
				prevNextButtons:false,
				contain:true,
				freeScroll:true,
				wrapAround:true,
				draggable:true,
				imagesLoaded:true
			}),
			info = $('<div>',{'class':'list-accreditations-info'});
		scope.after(info);
		scope.addClass("ready")
		items.mouseenter(function(){
			$(this).addClass('active').siblings().removeClass('active');
			var myInfo = $(this).find('.label');
			info.empty().append(myInfo.clone().hide().fadeIn());
		});
	});
});