$(function(){
	$('.signup-module').each(function(){
		$(this).find('input.btn.primary').val('Sign-up');
	});


	//Sign-up form checklist code
	
	//add header to umbraco form. This cannot be added in source code and is therefore appended.
	rows_header = '<div class="fields-header"><div class="radios"><span class="heading">UPDATES</span><span class="heading">EVENTS</span><span class="heading">ALL</span></div></div>';
	$('.umbraco-forms-form.signupform .titleanddescription').after(rows_header);
});