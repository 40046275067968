$(function(){
	$('.list-nav-page').each(function(){
		var scope = $(this),
			items = $(this).find('.list-nav-page-item'),
			sections = [],
			$slider = false;
		items.each(function(){
			var target = body.find($(this).find('a').attr('href'));
			if(target.length){
				sections.push(target);
			}
		});
		if(items.first().offset().top != items.last().offset().top){
			$slider = scope.flickity({
				pageDots:false,
				cellAlign:'left',
				prevNextButtons:false,
				contain:true,
				freeScroll:true,
				wrapAround:false,
				draggable:true,
				imagesLoaded:true
			});
		}
		body.on('scrolld',function(){
			var e;
			$(sections).each(function(i){
				if($(this).offset().top < scrolld + 0.1 * winH){
					e = i;
				}
			});
			items.eq(e).addClass('active').siblings('li').removeClass('active');
			if($slider){
				$slider.flickity('select',e,false,false);
			}
		});
	});
});