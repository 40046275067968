$(function(){
	$('.trigger-play').on('click',function(){
		var video = $(this).closest('.article-media.video').find('iframe');

		var playString = "&autoplay=1";

		if(!video.attr('src').includes('?')){
			playString = '?' + playString;
		}

		if(video.length){
			video.closest('.video').fadeIn();
			video[0].src += playString;
		}
	});
});