$(function(){
	var resizeTimer;
	var blogItems = $('.list-articles-item:not(.feat)');
	$(window).resize(function(){
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function(){
			var h = 0,
				x = 0;
			blogItems.removeClass('adjusted-height').attr('min-height',0);
			blogItems.each(function(i){
				if($(this).offset().top > x){
					blogItems.filter('.adjusted-height').removeClass('adjusted-height').css('min-height',Math.round(h));
					x = $(this).offset().top;
					h = 0;
				}
				if($(this).outerHeight() > h){
					h = $(this).outerHeight();
				}
				$(this).addClass('adjusted-height');
				if(i == blogItems.length - 1){
					blogItems.filter('.adjusted-height').removeClass('adjusted-height').css('min-height',Math.round(h));
					x = $(this).offset().top;
					h = 0;
				}
			});
		},500);
	}).resize();
});