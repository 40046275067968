$(function() {

    var $slider = null;

    function sizeTrackCards() {       
        
        //if window size less than 720 & slider not initialised
        if (window.innerWidth <= 720) {                    
            
            if ($slider == null) {
                $slider = $(".logo-track").flickity({
                    pageDots : false,
                    prevNextButtons : false,
                    cellAlign : "left",
                    adaptiveHeight : true
                });
            } else {
                $slider.flickity("resize");
            }
            
        } else if (window.innerWidth > 720) {
            
            if ($slider != null) {
                $slider.flickity("destroy");            
                $slider = null;
            }

            $(".logo-track__item").each(function() {

                var height = parseFloat($(this).find(".track-item__inner").height());
                var infoheight = parseFloat($(this).find(".track-item__info").outerHeight());
        
                $(this).height(height - infoheight + "px")
            });
        }
    };

    sizeTrackCards();
    $(window).on("resize", sizeTrackCards);

})