var $menu,
	hBtns;

function menu_open(el){
	var target = el.attr('class').split('-')[1],
		$target = $menu.find('#menu-gbl-'+target),
		pages = $menu.find('.menu-page');
	el.addClass('active').siblings('button[class*=toggle-]').removeClass('active');
	$menu.fadeIn(300);
	pages.hide();
	$target.fadeIn(300);
	// SET BODY CONDITIONS
	$('#window').css({
		'height' : $menu.outerHeight(),
		'overflow':'hidden'
	});
	body.addClass('menu-open');
}

function menu_close(){
	hBtns.removeClass('active');
	$menu.fadeOut(300);
	body.removeClass('menu-open');
	$('#window').attr('style','');
}

$(function(){
	$menu = $('#menu-gbl')
	hBtns = $('#header-gbl').find('button[class*=toggle-]');
	hBtns.click(function(){
		if($(this).hasClass('active')){
			menu_close();
		} else{
			menu_open($(this));
		}
	});
});