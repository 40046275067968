$(function() {

    var filterBlock = $(".filter__block");
    
    filterBlock.each(function() {

        var items = $(this).find("ul li");

        items.on("click", function() {
            $(items, ".active").removeClass("active")
            $(this).addClass("active");
        })
    })
})