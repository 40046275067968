$(function(){
    var input = $('#a93cd874-9853-42a8-a311-02efe1193fd0');
    //reset populated form fields within 'your details' section on pre-sue form
    $('.reset').click(function(){
        input.find('input, textarea').val('');
        input.find('select').val($('select option:first').val());
        input.find('input:radio').prop('checked',false);
    });

    //save form input within 'your details' section on pre-sue form
    $('.save').click(function(){
        localStorage.setItem("formTest", $('#9a0131ab-2a85-420d-c615-60c2e9784437').val());
    });
})